body{
    background-color: #f0f3f5 !important;
}


/* To prevent the default application of dark theme in supported browsers, addressing issue INA-3150 and INA-3914 */
@media (prefers-color-scheme: dark) {
    .r104f42s700-canary38:not(.rdg-light),
    .r104f42s700-canary49:not(.rdg-light) {
        --color: #000 !important;
        --border-color: #ddd !important;
        --summary-border-color: #aaa !important;
        --background-color: hsl(0deg 0% 100%)  !important;
        --header-background-color: hsl(0deg 0% 97.5%)  !important;
        --row-hover-background-color: hsl(0deg 0% 96%)  !important;
        --row-selected-background-color: hsl(207deg 76% 92%)  !important;
        --row-selected-hover-background-color: hsl(207deg 76% 88%)  !important;
        --checkbox-color: hsl(207deg 100% 29%)  !important;
        --checkbox-focus-color: hsl(207deg 100% 69%)  !important;
        --checkbox-disabled-border-color: #ccc  !important;
        --checkbox-disabled-background-color: #ddd  !important;
    }
}
/* Navigation Bar */
.ui.menu.main-navigation {
    background: #0050A4;
}

.ui.menu.main-navigation .item.image-container {
    padding: 0.6rem;
}

.ui.menu.main-navigation .item.image-container .ui.image {
    width: 85px;
}

.ui.dropdown.user-dropdown > i.icon {
    margin-left: 0.2rem;
}

.ui.dropdown.lang-dropdown > i.icon {
    margin-right: 0;
}
/* End of Navigation Bar */

/* Home container */
.ui.container.home-container {
    padding-left: 3px !important;
    padding-right: 3px !important;
    padding-top: 3px;
}
/* End of Home container */

/* Provider Selector */
.ui.container.provider-selector {
    margin-top: 12px;
}

.ui.card.provider-card {
    height: 80px;
}

.ui.card.provider-card .content .header {
    display: flex;
    height: 100%;
    align-items: center;
}
/* End of provider Selector */

/* Menus */
.ui.menu, .ui.menu .item {
    border-radius: 0 !important;
    border-bottom: 0;
}

.ui.menu .item.active, .ui.menu .item.active:hover {
    background-color: #0050A4;
    color: #fff;
}

.ui[class*="top attached"].menu {
    margin-top: 3px;
}

.ui.menu.border-bottom {
    height: 45px;
    border-bottom: 3px solid #0050A4;
}

.ui.menu.border-bottom .item {
    height: 43px;
}
/* End of Menus */

/* Sidebar */
.sidebar {
    height: 100%;
    overflow: auto;
    padding: 15px;
    position: fixed;
    top: 0px;
    z-index: 1000;
    transition: all 0.5s;
    box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.2);
}
/* End of Sidebar */

/*Grid Icons*/
i.red-border.icon {
    color: #D60D0D!important;
    border: 1px solid #D60D0D!important;
}

i.orange-border.icon {
    color: #FF9900!important;
    border: 1px solid #FF9900!important;
}

i.dark-blue-border.icon {
    color: #0054A4!important;
    border: 1px solid #0054A4!important;
}

i.light-green-border.icon {
    color: #8AC040 !important;
    border: 1px solid #8AC040 !important;
}

i.tab-exclamation {
    position: absolute;
    right: 4px;
    top: 4px;
    color: #D60D0D;
    font-size: 10px;
}
/*End of Grid Icons*/

/* Menu styles */
.ui.menu {
   margin-bottom: 0;
}

.ui.menu.tabs-menu {
    margin-top: 3px;
}
/* End of Menu styles */

/* Header styles */
.ui.header.attached.top {
    margin-top: 3px;
}

.ui.header {
    border-radius: 0 !important;
}
/* End of Header styles */

/* Segment styles */
.ui.segment {
    margin-top: 3px;
    border-radius: 0 !important;
}
.ui.segment.bottom.attached {
    margin-top: 0;
    margin-bottom: 3px;
}
/* End of Segment styles */

/* Provider Header styles */
.ui.grid {
   margin: 3px !important;
}
.ui .sticky-grid
{
    margin-top: 0rem !important;
    margin-left: 0rem !important;
    padding-top:0rem !important;
    padding-left:0rem !important;
    padding-right: 0rem !important;
    padding-bottom: 0rem !important;
}

.ui .sticky-grid .inner-grid{
    margin-top:0 !important;
    margin-bottom:0 !important;
}
.ui .sticky-grid .inner-grid .first-column
{
    padding-top: 0 !important;
    padding:0;
}

.ui .sticky-grid .inner-grid .first-column .border-all
{
    width: 40px;
    border-left: 1px solid #dae1e7;
    border-right: 1px solid #dae1e7;
}

.ui.grid.provider-header {
    background-color: #fff;
}
/* End of Provider Header styles */

.padding-top-0{
    padding-top:0px !important;
}

.padding-right-0 {
    padding-right: 0 !important;;
}

.padding-bottom-0{
    padding-bottom:0px !important;
}

.padding-left-0{
    padding-left:0px !important;
}

.padding-3 {
    padding: 3px !important;
}

.padding-top-3 {
    padding-top: 3px !important;
}

.padding-bottom-3 {
    padding-bottom: 3px !important;
}

.padding-right-3 {
    padding-right: 3px !important;
}

.padding-right-10 {
    padding-right: 10px;
}

.padding-left-10 {
    padding-left: 10px;
}

.padding-left-20 {
    padding-left: 20px;
}

.padding-bottom-10 {
    padding-bottom: 10px;
}

.padding-bottom-5 {
    padding-bottom: 5px;
}

.padding-left-right-0{
    padding-right:0px !important;
    padding-left:0px !important;
}

.padding-top-0{
    padding-top:0px !important;
}

.padding-bottom-0{
    padding-bottom:0px !important;
}

.margin-top-3 {
    margin-top: 3px !important;
}

.margin-bottom-0 {
    margin-bottom: 0px !important;
}

/* button */

.ui.light-blue.button,
.ui.light-blue.buttons {
    background-color: #00AEEF;
    color: #fff;
    text-shadow: none;
    background-image: none;
}

/* Gap between grid download and new button(s) */

.gap-btw-grid-buttons {
    margin-top: 7px !important;
}

/* end */

/* Form fields */
.ui.form .disabled.field {
    opacity: .6 !important;
}
/* End of form fields */

.custom-label-color {
    color: #00AEEC;
}
/* Grid Styles */

.grid-container-550 .rdg {
    height: 550px;
}

.grid-container-200 .rdg {
    height: 200px;
}
/* End of Grid Styles */

/* Fip header count styles */
.fip-header-count-container {
    display: flex;
    margin-top: 3px;
    position: relative;
}

.fip-header-count-container .count-blocks {
    height: 130px;
    margin-right: 8px;
    background-color: white;
    display: flex;
    padding: 0 15px;
    overflow-y: hidden;
    position: relative;
}

.fip-header-count-container .count-blocks:last-child {
    margin-right: 0;
}

.count-blocks .ui.statistic {
    margin-top: 12px !important;
    margin-bottom: 0 !important;
    margin-left: 0 !important;
    cursor: pointer;
}

.count-blocks .ui.statistic + .count-blocks .ui.statistic {
    margin: auto;
}

.count-blocks .statistic .value {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.count-blocks .ui.statistic .label {
    color: gray;
    text-transform: capitalize;
}

.count-blocks .ui.statistic .label.active {
    color: black;
}

.count-blocks .ui.statistic.active .label {
    color: black;
}
/* End of Fip header count styles */

.ui.purple.button,
.ui.purple.buttons {
    background-color: #9D3293;
    color: #fff;
    text-shadow: none;
    background-image: none;
}

.ui.blue.button,
.ui.blue.buttons {
    background-color: #0054A4;
    color: #fff;
    text-shadow: none;
    background-image: none;
}

.ui.light-blue.button,
.ui.light-blue.buttons {
    background-color: #00AEEF;
    color: #fff;
    text-shadow: none;
    background-image: none;
}

/* Modal Styles */

.ui.modal .actions {
    text-align: left;
}

/* End of Modal Styles */

.react-toast-notifications__container {
    z-index: 10001 !important;
}

.ui.yellow.label, .ui.yellow.labels .label {
    color: black !important;
}