.dropdownEditor,
.textEditor,
.dateEditor {
  appearance: none;

  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0 6px 0 6px;
  border: 2px solid #ccc;
  vertical-align: top;
  color: #000;
  background-color: hsl(0deg 0% 100%);

  font-family: inherit;
  font-size: 14px;
}
.dateEditor {
  padding: 0;
  border: 0;
  margin: 0;
}
.dropdownEditor:focus,
.textEditor:focus,
.dateEditor:focus {
  border-color: #66afe9;
  outline: none;
}

.dropdownEditor::placeholder,
.textEditor::placeholder,
.dateEditor::placeholder {
  color: #999;
  opacity: 1;
}
.rdg-cell .react-datepicker-wrapper {
  width: 100%;
}
.row-style-me-grid {
  background-color: #ff7f7f !important;
}
.row-style-me-grid:hover {
  background-color: #ff7f7f !important;
  /*cursor: pointer;*/
}
.react-datepicker__month-select,
.react-datepicker__year-select {
  border: 1px solid #ccc;
  padding: 1px;
}

.rootClassname {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 10px;
}

.rootClassname > .rdg {
  flex: 1;
}

.toolbarClassname {
  text-align: end;
}

.filter-cell {
  line-height: 35px;
  padding: 0;
}

.filter-containerClassname .filter-cell > div {
  padding: 0 8px;
}

.filter-containerClassname .filter-cell > div:first-child {
  border-bottom: 1px solid #ccc;
}

.filterClassname {
  width: 100%;
  padding: 8px 4px;
  margin-top: 4px;
  font-size: 14px;
  border: 1px solid #e2e8f0; /* Add border */
  border-radius: 4px; /* Add border radius for rounded corners */
  background-color: #fff; /* Add background color */
}
.filterClassname:focus{
  border-color : #85b7d9;
}
.filterClassname:focus-visible{
  border-color : #e2e8f0;
}

.filterInputClassname{
  padding: 4px 0;
}
/** Styles for tag label */
.grid-label {
  border-radius: 1em !important; /* Adjust the border-radius for a less rounded shape */
  padding: 0.5em 1em !important; /* Adjust the padding for width and height */
  line-height: 1em !important; /* Adjust the line-height to control the overall height */
}
